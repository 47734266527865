ejs-dropdownlist {
    .e-outline.e-input-group.e-control-wrapper {
        // line-height: 18px !important;
        letter-spacing: 0.028px !important;
    }

    .e-ddl.e-input-group input.e-input::placeholder {
        font-family: Arial !important;
    }

}

.e-list-item {
    color: var(--Gray-900, #101828);
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover,
.e-dropdownbase .e-list-item.e-hover {
    background: rgba(224, 224, 224, 0.20) !important;
}

.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
    color: #fac022 !important;
}

input.e-input,
textarea.e-input,
.e-input-group,
.e-input-group.e-control-wrapper {
    font-family: Arial !important;
}

.e-ddl.e-popup.e-outline.e-lib.e-control.e-popup-open {
    margin-top: 6px !important;
    border-radius: 6px;
    border: 1px solid var(--Border-color, #E0E0E0);
    background: var(--White, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}