//margins
$outset-xxxs: 4px;
$outset-xxs: 8px;
$outset-xs: 12px;
$outset-s: 16px;
$outset-m: 24px;
$outset-l: 32px;
$outset-xl: 40px;
$outset-xxl: 48px;
$outset-xxxl: 56px;

//paddings
$inset-xxxs: 4px;
$inset-xxs: 8px;
$inset-xs: 12px;
$inset-s: 16px;
$inset-m: 24px;
$inset-l: 32px;
$inset-xl: 40px;
$inset-xxl: 48px;
$inset-xxl: 56px;

//borders
$border-radius-l: 12px;
$border-radius-m: 8px;
$border-radius-s: 6px;
$border-width: 1px;

// schedule
$schedule-cell-height: 120px;
