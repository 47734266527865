@use 'colors';
@use 'spacings';

::-webkit-scrollbar-track {
  background-color: colors.$scrollbar-track;
  border-radius: spacings.$border-radius-m;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: spacings.$border-radius-m;
}

::-webkit-scrollbar-thumb {
  border-radius: spacings.$border-radius-m;
  background-color: colors.$scrollbar-thumb;
  border: 8px solid colors.$scrollbar-border-thumb;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
  }
}