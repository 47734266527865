@use 'colors';

// treegrid custom styles start

.e-treegrid {
    & .e-gridheader .e-table {
        background-color: colors.$primary;
    }

    & .e-gridcontent {
        border-radius: 8px;

        & .e-rowcell {
            // border-bottom: 0;

            &.e-ellipsistooltip {
                &.e-treerowcell[class*='level1'].e-templatecell {
                    padding-left: 42px;
                }

                &[class*='level2'].e-templatecell {
                    padding-left: 84px;
                }
            }

            &.e-rowdragdrop.e-rowdragdropcell.e-lastrowcell {
                border-bottom-width: 0;
            }
        }

        & .e-table {
            border-radius: 0 0 8px 8px;
        }
    }

    & .e-rowdragheader {
        background-color: colors.$primary;
    }

    & .e-treegridexpand,
    & .e-treegridcollapse {
        font-size: 12px;
    }

    & .e-treegridexpand::before {
        content: '\e87d';
    }

    & .e-treegridcollapse::before {
        content: '\e70d';
    }

    & .e-cloneproperties .hidden-on-hover {
        display: none;
    }
}

// treegrid custom styles end