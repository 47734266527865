@use 'colors';

* {
  font-family: Arial, Helvetica, sans-serif, sans-serif;
}

h1 {
  font-size: 32px;
  font-weight: 700;
  color: colors.$primary;
  font-family: Corbel, Helvetica, sans-serif, sans-serif;
  line-height: 120%;
  letter-spacing: -0, 25px;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  font-family: Corbel, Helvetica, sans-serif, sans-serif;
  line-height: 32px;
  letter-spacing: 0;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  font-family: Corbel, Helvetica, sans-serif, sans-serif;
  line-height: 24px;
  letter-spacing: 0, 15px;
}

h4 {
  font-size: 16px;
  font-weight: 700;
  font-family: Corbel, Helvetica, sans-serif, sans-serif;
  line-height: 24px;
  letter-spacing: 0, 26px;
}

h5 {
  font-size: 20px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
  line-height: 32px;
  letter-spacing: 0;
}

h6 {
  font-size: 16px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
  line-height: 24px;
  letter-spacing: 0, 32px;
}

p {
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
}

b {
  font-size: 16px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
}

small {
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
}

strong {
  font-size: 16px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
}

/* .accordion-header-text {
  font-size: 16px;
  color: black !important;
} */

.small-content-text {
  font-size: 12px;
  line-height: 16px;
}

span {
  font-family: Arial, Helvetica, sans-serif, sans-serif;
}

.bold {
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
}

input {
  font-family: Arial, Helvetica, sans-serif, sans-serif !important;
}
