.e-toast {

  .e-toast-error-icon::before,
  .e-toast-success-icon::before {
    margin-right: 14px;
    font-size: 16px;
  }

  .e-toast-close-icon {
    margin-left: 8px !important;
    width: 32px !important;

    &::before {
      content: '\e7e7';
      font-size: 16px;
    }
  }
}

.e-toast-container {
  .e-toast {
    border-radius: 6px;
    box-shadow: unset !important;

    &.e-toast-danger {
      background: #c33838 !important;
    }

    &.e-toast-danger::before {
      display: flex;
      align-items: center;
    }

    &.e-toast-success {
      background: #0A965C !important;
    }

    &.e-toast-success::before {
      display: flex;
      align-items: center;
    }
  }
}

.e-toast-content {
  line-height: 20px;
}