goatsports-scheduler-listbox-item,
goatsports-user-program-workout-item {
    .e-accordion {
        border-right: unset;
        border-left: unset;
        margin-bottom: -1px;

        .e-acrdn-item.e-select {
            border: unset;

            &:last-child {
                border: unset;
            }

            >.e-acrdn-header {
                min-height: 64px !important;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background: #f3f3f3 !important;

                .e-acrdn-header-content {
                    width: 100% !important;
                    line-height: 1 !important;
                }

                .e-toggle-icon {
                    top: 14px !important;

                    .e-icons {
                        color: #7c7c7c !important;
                        font-size: 16px;
                    }
                }
            }
        }

        // Common styles for modifier classes
        &.pending-workout,
        &.missed-workout,
        &.done-workout {
            background: #fff !important;
            border-color: #e0e0e0 !important;

            .e-acrdn-item.e-select>.e-acrdn-header {
                display: flex;
                background: #fafafa !important;
            }

            .accordion-header-text {
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
            }

            .e-drag-and-drop {
                font-weight: bold;
            }

            .e-toggle-icon {
                color: #7c7c7c !important;
            }
        }

        // Specific styles for each modifier class
        &.pending-workout {
            .e-acrdn-item.e-select>.e-acrdn-header {
                border-left: 4px solid rgba(34, 159, 250, 0.5) !important;
                border-right: unset;
            }

            .accordion-header-text {
                color: rgba(0, 0, 0, 0.5);
            }

            .e-drag-and-drop {
                cursor: move;
                color: #bdbdbd !important;
            }
        }

        &.missed-workout {
            .e-acrdn-item.e-select>.e-acrdn-header {
                border-left: 4px solid #229ffa !important;
            }

            .e-acrdn-panel {
                // background-color: #fff2f2 !important;
                color: #c33838 !important;
            }

            .accordion-header-text {
                color: #c33838 !important;
            }

            .e-drag-and-drop {
                cursor: move;
                color: #bdbdbd !important;
            }
        }

        &.done-workout {
            .e-acrdn-item.e-select>.e-acrdn-header {
                border-left: 4px solid #229ffa !important;
            }

            .accordion-header-text {
                color: #000;
            }

            .e-drag-and-drop {
                cursor: not-allowed;
                color: #bdbdbd !important;
            }
        }

        &.program-workout {
            .e-drag-and-drop {
                cursor: move;
            }
        }
    }

    .accordion-header-text {
        width: 100%;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
    }
}