.e-multiselect {
    .e-input-group {
        .e-ddl-icon::before {
            content: "\e729" !important;
        }
    }
}

.e-outline.e-input-group.e-control-wrapper,
.e-outline.e-input-group,
.e-outline.e-input-group:not(.e-float-icon-left),
.e-outline.e-float-input,
.e-outline.e-float-input.e-control-wrapper,
.e-outline.e-input-group:not(.e-float-icon-left),
.e-outline.e-input-group.e-success:not(.e-float-icon-left),
.e-outline.e-input-group.e-warning:not(.e-float-icon-left),
.e-outline.e-input-group.e-error:not(.e-float-icon-left),
.e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-outline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-outline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-outline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border-color: #e0e0e0;
}