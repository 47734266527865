@use 'colors';
@use 'spacings';

// Grid custom styles start
.e-grid {
    border-radius: spacings.$border-radius-m;

    .e-headercell {
        background-color: colors.$primary;
        color: colors.$background-inner-card;
    }

    .e-headercontent {
        border-right: unset !important;
    }

    .e-headercelldiv {
        font-size: 16px !important;
        color: #FFF;
        font-family: Arial;
        font-style: normal;
        font-weight: 500;
        // line-height: 21px;
        letter-spacing: 0.2px;

        span {}
    }

    .e-detailheadercell {
        background-color: colors.$primary;
    }

    &.modal-grid {
        .e-content {
            max-height: 256px !important;
        }
    }

    .e-gridheader {
        // padding-right: 10px !important;
        border-radius: spacings.$border-radius-m spacings.$border-radius-m 0 0;
        background-color: colors.$primary;
    }

    .e-gridpager {
        border-radius: 0 0 spacings.$border-radius-m spacings.$border-radius-m;
    }

    .e-gridcontent {
        // cursor: pointer;

        .e-content {
            overflow: auto !important;
        }

        .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd) {
            background-color: #fff !important;

            &:hover {
                background-color: #eaf6ff !important;
            }
        }
    }

    td.e-active {
        background-color: colors.$background-selected-row !important;
    }

    &.e-gridhover {
        tr.e-row:hover {
            .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
                background-color: colors.$background-hover-row !important;
            }
        }
    }

    /* th {

        &.e-rowdragheader,
        &.e-detailheadercell {
            background: #272e5a !important;
        }
    } */

    .e-icon-grightarrow,
    .e-icon-gdownarrow {
        font-size: 16px;
        font-weight: 600;
    }

    .e-icon-grightarrow::before {
        content: '\e748';
    }

    .e-icon-gdownarrow::before {
        content: '\e729';
    }

    .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
        box-shadow: unset !important;
    }
}

.e-detailrow {
    th.e-detailindentcell {
        border-top: 1px solid #e0e0e0 !important;
        border-right: unset !important;
    }
}

.e-detailrowcollapse,
.e-detailrowexpand {
    background: rgba(217, 217, 217, 0.3) !important;
    // border-bottom-color: #000 !important;
    text-align: center !important;
    vertical-align: middle !important;
    border-bottom-color: #e0e0e0 !important;

    a {
        opacity: 1 !important;
        display: inline-block !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.e-grid .e-icon-ascending::before {
    font-size: 16px !important;
    color: #fff !important;
    content: '\e7df' !important;
}

.e-grid .e-icon-descending::before {
    font-size: 16px !important;
    color: #fff !important;
    content: '\e7d8' !important;
}

.e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {

    .e-checkbox-wrapper .e-frame,
    .e-css.e-checkbox-wrapper .e-frame {
        border-radius: 4px !important;
        border: 1px solid #fff !important;
        background: transparent !important;
    }

    .e-frame.e-icons.e-check {
        background: #fac022 !important;
        color: #fff !important;
        border-color: #fac022 !important;
    }

    .e-checkbox-wrapper .e-frame.e-stop,
    .e-css.e-checkbox-wrapper .e-frame.e-stop {
        background: #fac022 !important;
        border-color: #fac022 !important;
        color: #fff !important;
    }
}

.e-rowcell.e-gridchkbox {

    .e-checkbox-wrapper .e-frame.e-stop,
    .e-css.e-checkbox-wrapper .e-frame.e-stop {
        background: #fac022 !important;
        border-color: #fac022 !important;
        color: #fff !important;
    }

    .e-checkbox-wrapper .e-frame.e-check,
    .e-css.e-checkbox-wrapper .e-frame.e-check {
        background: #fac022 !important;
        border-color: #fac022 !important;
        color: #fff !important;
    }

    .e-checkbox-wrapper .e-frame,
    .e-css.e-checkbox-wrapper .e-frame {
        border-radius: 4px !important;
        border: 1px solid #E0E0E0 !important;
    }
}

.e-checkbox-wrapper.e-checkbox-disabled,
.e-css.e-checkbox-wrapper.e-checkbox-disabled {
    cursor: auto !important;

    .e-frame.e-icons {
        border-color: rgba(224, 224, 224, 0.5) !important;
    }
}

// Grid custom styles end