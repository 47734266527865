@use 'colors';
@use 'spacings';

// Scheduler custom styles start

.e-schedule .e-schedule-table {
  border-collapse: collapse;
}

.e-schedule {
  padding: 16px !important;
  border-radius: spacings.$border-radius-l;
  background-color: colors.$background-card;

  .e-header-cells span {
    line-height: 18px !important;
  }
}

.e-schedule .e-schedule-toolbar {
  box-shadow: none;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells {
  height: fit-content;
  position: relative;
  z-index: 1000;
}

.e-schedule .e-month-view .e-appointment {
  background-color: #fff;
  height: fit-content !important;
  border: unset;
  position: unset;
  width: 100% !important;
}

.e-schedule .e-month-view .e-work-cells {
  border: 1px solid #e0e0e0 !important;
}

.e-schedule .e-month-view .e-appointment.e-appointment-border,
.e-schedule .e-month-view .e-appointment:focus {
  box-shadow: none !important;
}

.e-schedule .e-month-view .e-appointment-wrapper {
  position: unset;
}

.e-work-days {
  background-color: #fff !important;
}

.e-work-days:hover {
  background-color: #fff !important;
}

.e-work-cells:not(.e-work-days) {
  border: 1px solid #e0e0e0 !important;
  background-color: colors.$background-light-yellow !important;

  .e-appointment {
    background-color: colors.$background-light-yellow !important;
  }
}

.e-work-cells:not(.e-work-days):hover {
  background-color: colors.$background-light-yellow !important;
}

.e-other-month {
  border: 1px solid #e0e0e0 !important;
  background-color: #f9f9f9 !important;

  .e-appointment {
    background-color: #f9f9f9 !important;
  }

  .e-date-header {
    color: #cecece;
  }
}

.e-other-month:hover {
  background-color: #f9f9f9 !important;
}

.e-work-cells:not(.e-work-days).e-other-month {
  background-color: #f9f3e4 !important;

  .e-appointment {
    background-color: #f9f3e4 !important;
  }
}

.e-work-cells:not(.e-work-days).e-other-month:hover {
  background-color: #f9f3e4 !important;
}

.e-schedule-table:not(.e-content-table):not(.e-outer-table) {
  .e-header-cells {
    border-color: #fff;
  }
}

.e-date-header {
  margin: spacings.$outset-s spacings.$outset-m !important;
}

.e-schedule .e-outer-table>tbody>tr:first-child>td {
  border-radius: spacings.$border-radius-m spacings.$border-radius-m 0 0;
}

goatsports-scheduler {
  .e-schedule .e-outer-table>tbody>tr:first-child>td {
    border-radius: 0 !important;
  }
}

.e-header-cells {
  background-color: colors.$secondary !important;
  padding: spacings.$inset-m !important;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$primary;
    font-weight: 700;
  }
}

// schedule custom styles end