goatsports-meal-scheduler-listbox-item,
goatsports-meal-item {
    .e-accordion {
        border-right: unset;
        border-left: unset;
        margin-top: -1px;

        .e-acrdn-header {
            padding-right: 50px !important;
        }

        .e-acrdn-item.e-select {
            border: unset;

            &:last-child {
                border: unset;
            }

            >.e-acrdn-header {
                min-height: 64px !important;
                display: flex;
                align-items: center;

                // border-left: 4px solid #6adf33 !important;
                .e-acrdn-header-content {
                    width: 100% !important;
                    line-height: 1 !important;
                }

                .e-toggle-icon {
                    top: 14px !important;

                    .e-icons {
                        color: #7c7c7c !important;
                        font-size: 16px;
                    }
                }
            }
        }

        // Common styles for modifier classes
        &.tracked-meal,
        &.pending-meal,
        &.program-meal {
            .e-drag-and-drop {
                font-weight: bold;
            }

            .e-toggle-icon {
                color: #7c7c7c !important;
            }
        }

        // Shared styles for tracked and pending meals
        &.tracked-meal,
        &.pending-meal {
            background: #fff !important;
            border-color: #e0e0e0 !important;

            .e-acrdn-item.e-select>.e-acrdn-header {
                display: flex;
                background: #fafafa !important;
            }

            .accordion-header-text {
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
                text-overflow: ellipsis;
                display: inline-block;
                overflow: hidden;
            }

            .e-drag-and-drop {
                color: #bdbdbd !important;
                font-size: 16px;
            }
        }

        // Specific styles for tracked meal
        &.tracked-meal {
            .e-acrdn-item.e-select>.e-acrdn-header {
                border-left: 4px solid #6adf33 !important;
            }

            .accordion-header-text {
                color: #000;
            }

            .e-drag-and-drop {
                cursor: not-allowed;
            }
        }

        // Specific styles for pending meal
        &.pending-meal {
            .e-acrdn-item.e-select>.e-acrdn-header {
                border-left: 4px solid rgba(106, 223, 51, 0.5) !important;
            }

            .accordion-header-text {
                color: rgba(0, 0, 0, 0.5);
            }

            .e-drag-and-drop {
                cursor: move;
            }
        }

        // Styles for program meal
        &.program-meal {
            .e-drag-and-drop {
                cursor: move;
            }
        }
    }

    .accordion-header-text {
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
    }

    .servings-number {
        color: #7c7c7c;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }
}