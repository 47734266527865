@use 'colors';
@use 'spacings';

.e-tooltip-wrap .e-tip-content {
    padding: 3px 12px;
    color: colors.$primary;
    // background-color: colors.$secondary !important;
    background-color: #fcdf90;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    opacity: 1 !important;
}

.e-tooltip-wrap {
    border-radius: spacings.$border-radius-s;
}

.e-tooltip-wrap.e-popup {
    background-color: #fcdf90 !important;
    border: 2px solid #fcdf90 !important;
}

.e-tooltip-wrap.e-popup {
    border: 2px solid transparent;
    opacity: 1 !important;
}

.e-arrow-tip-inner::before {
    display: none;
}

.e-arrow-tip-outer::before {
    background-color: #fcdf90 !important;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-left {
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fcdf90 !important;
    border-top: 12px solid transparent;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-top {
    border-bottom: 12px solid #fcdf90 !important;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
}

.e-arrow-tip-inner::before {
    background-color: #fcdf90 !important;
}