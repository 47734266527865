.e-dlg-header {
  color: var(--Grey-Grey-Black, #000);
  font-family: Corbel;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.e-dialog,
.e-dlg-header-content,
.e-dlg-content,
.e-footer-content {
  border-radius: 8px;
  border: #e0e0e0;
  background: #f9f9f9 !important;
}

.e-dialog .e-footer-content {
  padding: 0 24px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.e-dialog .e-dlg-content {
  padding: 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ejs-grid {
    display: block !important;
  }
}

.e-dialog .e-dlg-header-content {
  padding: 24px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.e-dialog,
.e-confirm-dialog {
  border-radius: 8px !important;

  .custom-btn {
    padding: 8px 24px !important;
    line-height: 21px !important;
    letter-spacing: 0.2px !important;
    border-radius: 4px !important;
  }

  .custom-cancel-btn {
    padding: 8px 24px !important;
    line-height: 21px;
    letter-spacing: 0.2px;
    border-radius: 4px !important;
  }

  .custom-small-btn {
    padding: 8px 24px !important;
    line-height: 21px;
    letter-spacing: 0.2px;
    border-radius: 4px !important;
  }
}

.e-confirm-dialog {
  .e-dlg-header {
    color: #000;
    font-family: Corbel;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .e-dlg-content {
    color: #7c7c7c;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.26px;
  }

  .e-primary {
    color: #272e5a;
    text-align: center;
    font-family: Arial !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 21px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
}

.e-dlg-header {
  color: #000;
  font-family: Corbel;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.e-dialog {
  border-radius: 8px !important;

  .e-dlg-header-content {
    padding: 24px !important;
  }

  .e-primary {
    padding: 8px, 24px, 8px, 24px !important;
    border-radius: 6px !important;
  }

  .e-dlg-header {
    font-weight: 700 !important;
    font-size: 24px !important;
  }

  .e-dlg-content {
    padding: 0px 24px !important;
  }

  .e-footer-content {
    padding: 0 24px 24px 24px !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .exercise-caption {
    position: absolute !important;
  }

  .e-dlg-closeicon-btn {
    margin-right: -10px !important;
  }
}

.e-confirm-dialog {
  .e-footer-content {
    display: flex;
    justify-content: flex-end;

    button.custom-cancel-btn {
      order: 1;
    }

    button.custom-btn {
      order: 2;
    }
  }
}

.e-icon-dlg-close {
  font-size: 14px !important;
  color: #757575 !important;
}

.e-icon-dlg-close::before {
  content: '\e7e7' !important;
  font-size: 14px !important;
}