.video-container {
    position: relative;
    height: 0;
    overflow: hidden;
    width: 100%;
  }
  
  .video-container>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .landscape {
    padding-bottom: 56.25%;
    /* 16:9 Aspect Ratio */
  }
  
  .portrait {
    padding-bottom: 133.33%;
    /* 3:4 Aspect Ratio */
  }
  
  .portrait {
    ngx-lite-vimeo {
      .ngx-lite-video {
        padding: 133.33% 0 0;
      }
    }
  }
  
  youtube-player {
    youtube-player-placeholder {
      width: 100% !important;
      height: 100% !important;
    }
  
    div {
      width: 100%;
      height: 100%;
  
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }