@use 'colors';

ejs-textbox {
  ::placeholder {
    color: colors.$secondary-body-color !important;
  }

  .e-input-group.e-control-wrapper.e-outline {
    font-size: 13px !important;
    line-height: 18px !important;
    font-family: Arial, Helvetica, sans-serif !important;
  }
}

.disable-textarea-resize {

  textarea.e-input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group textarea,
  .e-input-group.e-control-wrapper textarea {
    resize: none !important;
  }
}

.e-outline.e-input-group.e-error,
.e-outline.e-input-group.e-control-wrapper.e-error,
.e-outline.e-input-group.e-error:not(.e-float-icon-left),
.e-outline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left),
.e-outline.e-float-input.e-error,
.e-outline.e-float-input.e-input-group.e-error,
.e-outline.e-float-input.e-control-wrapper.e-error,
.e-outline.e-float-input.e-input-group.e-control-wrapper.e-error {
  border-color: colors.$alert-color !important;
}

.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group textarea.e-input,
.e-input-group.e-control-wrapper textarea.e-input {
  border-color: colors.$border-color;
}