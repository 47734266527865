@use './theme/material';
@use 'colors';
@use 'spacings';
@use 'fonts';
@use 'typography';
@use './node_modules/@syncfusion/ej2-icons/styles/tailwind.scss';

@use './syncfusion-overrides/textbox-overrides';
@use './syncfusion-overrides/dropdownlist-overrides';
@use './syncfusion-overrides/grid-overrides';
@use './syncfusion-overrides/treegrid-overrides';
@use './syncfusion-overrides/datepicker-overrides';
@use './syncfusion-overrides/daterangepicker-overrides';
@use './syncfusion-overrides/dialog-overrides';
@use './syncfusion-overrides/schedule-overrides';
@use './syncfusion-overrides/toast-overrides';
@use './syncfusion-overrides/pager-overrides';
@use './syncfusion-overrides/tab-overrides';
@use './syncfusion-overrides/multiselect-overrides';
@use './syncfusion-overrides/numeric-textbox-overrides';
@use './syncfusion-overrides/breadcrumb-overrides';
@use './syncfusion-overrides/tooltip-overrides';
@use './syncfusion-overrides/menu-overrides';

@use './global-styles/scrollbar';
@use './global-styles/video-players';
@use './global-styles/workout-item';
@use './global-styles/meal-item';

@use 'swiper/scss';
@use 'swiper/scss/pagination';

* {
  box-sizing: border-box;
  // position: relative;
  min-width: 0;
}

body {
  min-height: 100dvh;
  margin: 0;
  background-color: colors.$background-body;
}

.custom-chat-badge {
  font-size: 14px;
  font-weight: 700;
}

.full-size-skeleton {
  width: 100%;
  height: 100%;
  display: block;
}

main {
  height: calc(100dvh - 65px);
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;

  header {
    margin-bottom: spacings.$outset-m;
  }
}

.success {
  color: colors.$success !important;
}

.error-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .characters-limit-hint {
    align-self: flex-end;
  }
}

.invisible {
  visibility: hidden;
}

.no-data {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #272e5a;
    font-size: 20px;
    font-weight: 500;
    font-family: Corbel;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
}

.active-chat.e-icons.e-comment-show:before {
  color: #fac022;
}

.swiper {
  overflow-y: auto;
}

.characters-limit-hint {
  color: #9e9e9e;
  text-align: right;
  font-family: Arial;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.e-btn.e-today {
  color: #272e5a !important;
}

.required {
  color: colors.$alert-color !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.e-active {
  background-color: unset !important;
  color: unset !important;
}

button {
  &:disabled {
    opacity: 0.5 !important;
  }

  .e-btn-icon.e-icons {
    transform: scale(1.5);
  }
}

.e-toolbar-right {
  .e-today {
    display: none !important;
  }
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
  content: '\e729' !important;
}

.e-header-popup {
  .e-day {
    border-radius: spacings.$border-radius-s !important;
    height: 35px !important;
    line-height: 35px !important;
    width: 50px !important;
  }

  tr:last-child {
    td {
      border-bottom: spacings.$border-width solid colors.$border-color !important;
    }
  }

  .e-footer-container {
    justify-content: center;

    button {
      color: #000 !important;
      background-color: colors.$secondary !important;
      padding: 4px 16px;
      border-radius: spacings.$border-radius-s !important;
      font-size: 16px;
      line-height: 26px;
    }
  }
}

// first level card custom styles
.e-card {
  box-shadow: none !important;
  border-radius: 12px !important;
  border: 1px solid #e0e0e0 !important;
  background-color: #f9f9f9 !important;
  padding: spacings.$inset-m spacings.$inset-m !important;
  justify-content: flex-start !important;

  &>* {
    justify-content: flex-start;
  }
}

// second level card custom styles
.inner-card {
  border-radius: spacings.$border-radius-m !important;
  padding: spacings.$inset-s spacings.$inset-m !important;
  border: spacings.$border-width solid colors.$border-inner-card !important;
  background-color: colors.$background-inner-card !important;
}

.e-outline {
  border-color: #e0e0e0 !important;
  background: #fff !important;
}

.e-outline.e-input-focus {
  border-color: #fac022 !important;
}

.input-readonly {
  border-color: #f4f4f4 !important;
  background: #f4f4f4 !important;
}

.e-dropdown-btn:active,
.e-dropdown-btn.e-btn:active {
  box-shadow: unset !important;
}

.custom-dropdown-button {
  padding: 8px 12px;
  background-color: #fff !important;
  border-radius: 4px;
  border-color: #e0e0e0;
  display: flex;
  gap: 8px;
  align-items: center;

  color: #7c7c7c !important;
  font-family: Arial;
  font-size: 13px;
  line-height: 1;
}

goatsports-chat-feed {
  .e-accordion {
    border-radius: 0 0 12px 12px !important;

    .e-item-focus {
      border: unset !important;
    }
  }

  .e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content>* {
    overflow: auto;
  }
}

.danger-btn {
  color: #c33838 !important;
  background-color: unset !important;
}

.unset-background {
  background-color: unset !important;
}

.round-btn {
  background-color: #f2f2f2 !important;
}

// dropdown popup custom styles
.e-dropdown-popup {
  background: #fff !important;
  border: 1px solid colors.$scrollbar-thumb;
  border-radius: 4px;
  margin-left: 2px;
  margin-top: 2px;

  ul {
    box-shadow: unset;
    padding: 4px;

    li {
      color: #7c7c7c;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }
}

//textbox custom styles
.input-label {
  display: block;
  color: colors.$grey;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.028px;
  margin-bottom: 8px !important;
}

.input-inline-label {
  display: block;
  color: colors.$secondary-body-color-87;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.028px;
}

.input-readonly-mode {
  .e-input-focus {
    border-color: #e0e0e0 !important;
    box-shadow: unset !important;
  }
}

goatsports-notifications-list {
  .e-listview.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge) {
    padding: spacings.$inset-s spacings.$inset-m;
  }

  .e-listview.e-list-template .e-list-wrapper.e-list-multi-line {
    padding: spacings.$inset-s spacings.$inset-m;
  }

  .e-list-item {
    border-radius: spacings.$border-radius-m;
  }
}

.action-buttons-border {
  display: inline-block !important;
  padding: 0px !important;
  width: 32px !important;
  border-radius: 50% !important;

  &:last-child {
    margin-right: 0px;
  }

  ejs-tooltip {
    height: 32px;

    button {
      height: 32px;
    }

    button::before {
      font-size: 16px !important;
    }
  }
}

.gray-span-text {
  font-size: 14px;
  font-weight: 400;
  color: colors.$gray-text;
}

.gray-small-text {
  font-size: 10px;
  font-weight: 400;
  color: colors.$gray-text;
}

.grid-span-text {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.primary-text-color {
  color: colors.$primary;
}

.accent-text-color {
  color: colors.$secondary;
}

.custom-drag-placeholder {
  background: #d9d9d966;
  border: 1px dotted #757575;
  border-radius: 4px;
  min-height: 64px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-preview {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  height: 64px;
  width: 230px;
  cursor: move !important;
}

.drag-preview-done {
  border-color: #e0e0e0;
  background-color: #fafafa;
  color: #000;
  border-left: 4px solid #229ffa !important;
}

.drag-preview-pending {
  border-color: #e0e0e0;
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.5);
  border-left: 4px solid rgba(34, 159, 250, 0.5) !important;
}

.drag-preview-missed {
  border-color: #e0e0e0;
  background-color: #fafafa;
  color: #c33838;
  border-left: 4px solid #229ffa !important;
}

.drag-preview-meal {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  height: 64px;
  width: 230px;
  cursor: move !important;

  &__pending {
    border-color: #e0e0e0;
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.5);
    border-left: 4px solid rgba(106, 223, 51, 0.5) !important;
  }

  &__tracked {
    border-color: #e0e0e0;
    background-color: #fafafa;
    color: #000;
    border-left: 4px solid #6adf33 !important;
  }
}

// Accordion custom styles
.e-accordion {
  .e-accordion .e-acrdn-item.e-select>.e-acrdn-header {
    justify-content: center;
  }
}

.e-accordion .e-acrdn-item.e-select.e-active>.e-acrdn-header .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-item-focus>.e-acrdn-header .e-acrdn-header-content {
  color: #000 !important;
}

.e-primary {
  box-shadow: unset !important;
}

.menu-color {
  width: 20px;
  height: 20px;
}

.e-appbar {
  height: 65px;

  .e-dropdown-popup {
    min-width: 400px !important;
  }
}

.e-icon-first::before {
  content: '\e833' !important;
}

.e-icon-last::before {
  content: '\e818' !important;
}

.e-spin-up::before {
  content: '\e87a' !important;
}

.e-spin-down::before {
  content: '\e70d' !important;
}

.e-dropdown-popup ul {
  padding: 0;
}

.e-constant {
  position: relative;
  bottom: 8px;
}

.e-dlg-content {
  overflow: hidden !important;

  .e-constant {
    position: unset !important;
  }

  .e-pager .e-pagerdropdown {
    margin-top: -4px !important;
  }

  .e-pager div.e-parentmsgbar {
    padding-top: 30px !important;
  }
}

.e-pager .e-pagesizes {
  display: inline-flex;
  flex-direction: column-reverse;
}

.e-dropdown-popup {
  .notification {
    min-width: 400px !important;
  }
}

.e-dropdown-popup {
  goatsports-notifications-list {
    .notification-text {
      line-height: 36px;

      span {
        font-size: 16px;
      }
    }

    b:first-child {
      font-size: 15px;
    }
  }
}

.e-footer-content {
  margin-top: 20px;
}

.e-badge.e-badge-overlap {
  top: 0 !important;
}

.child-row__bold {
  color: colors.$black-87 !important;
  font-size: 16px !important;
  font-family: Arial;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.32px;
  font-weight: 700;
}

.child-row {
  color: colors.$black-87 !important;
  font-size: 16px !important;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.attributes-content_div,
.workout-caption {
  .child-row {
    margin-left: unset !important;
  }
}

.parent-row {
  font-size: 16px !important;
}

.feed-section-header-actions {
  .e-badge.e-badge-overlap {
    top: unset !important;
    left: unset !important;
  }

  .e-badge.e-badge-dot {
    position: unset !important;
  }
}

.asterix label.e-label-top::after {
  content: '*';
  color: colors.$alert-color;
}

goatsports-single-program {
  .e-accordion {
    background: #fafafa !important;
  }

  .e-acrdn-header {
    background: #fafafa !important;
  }

  .e-acrdn-panel {
    background: #ffffff !important;
  }

  .e-drag-and-drop::before {
    cursor: move !important;
  }
}

goatsports-client-overview-edit {

  .e-headercell,
  .e-templatecell {
    padding: 0px 12px !important;
  }

  .e-headercell:nth-child(2),
  .e-templatecell:nth-child(2) {
    padding: 0px !important;
  }

  .ejs-textbox {
    height: 32px !important;
  }

  .e-outline.e-input-group {
    height: 32px !important;
    min-height: 32px !important;
  }
}

.e-none {
  display: none !important;
}

.e-chips-close::before {
  content: '\e878' !important;
}

.attributes-input-wrapper {
  ejs-textbox {
    width: 194px;
  }
}

.wrapper-drop-down-list {
  ejs-dropdownlist {
    width: 240px !important;
  }
}

goatsports-client-overview {
  .e-tbar-btn:hover {
    background: unset !important;
    background-color: unset !important;
  }

  .e-tbar-btn-text {
    margin-right: 8px !important;
  }

  .e-table-container {
    margin-top: 24px !important;
  }

  .e-toolbar .e-toolbar-item:not(.e-separator) {
    padding: 0px !important;
  }
}

ejs-dialog {
  goatsports-chat-data-content {
    height: 350px !important;
    overflow: auto !important;
  }
}

.e-input-group-icon.e-ddl-icon {
  color: #9e9e9e !important;
}

.e-link.e-numericitem.e-spacing.e-currentitem.e-active {
  background: colors.$secondary !important;
  color: #fff !important;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
  border-left: 8px solid transparent !important;
  border-right: 8px solid transparent !important;
  border-top: 8px solid #fcdf90 !important;
  border-bottom: 0px solid #fcdf90 !important;
}

.e-toolbar .e-toolbar-item .e-tbar-btn:focus {
  background: unset !important;
}

.clear-btn {
  background: transparent !important;
  color: colors.$primary !important;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.028px;
  text-transform: capitalize;
}

.filter-text {
  color: #7c7c7c;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.028px;
}

.input-small {
  width: 100px !important;
}

.e-icon-descending::before {
  color: #fff !important;
  content: '\e7df' !important;
  font-size: 16px;
}

.e-sortfilterdiv {
  width: 16px !important;
  height: 16px !important;
  padding: 0px !important;
}

.e-headertext {
  color: #fff !important;
}

/* .e-icons.e-search.e-input-group-icon {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  margin-right: 8px !important;
} */

//OVERRIDE SYNCFUSION CLASSES START

.e-error {
  color: #c33838 !important;
  border-color: #c33838 !important;
  font-family: Arial;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
}

.e-success {
  color: var(--Green-chart-color, #08ac18);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

//OVERRIDE SYNCFUSION CLASSES END

.remove-all-btn {
  background: #fff !important;
  box-shadow: none !important;
  color: #000;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  text-transform: none;
}

.add-ingredient-btn {
  border-radius: 4px;
  background: rgba(229, 231, 235, 0.6) !important;
  box-shadow: none !important;
  color: #272e5a;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  text-transform: none;
}

.remove-circle-btn {
  background: inherit !important;
  box-shadow: none !important;

  span {
    color: #d8d8d8;
  }
}

.hidden {
  visibility: hidden;
}

.e-emptyrow {
  td {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, sans-serif;
    line-height: 24px;
  }
}

ejs-textbox.instructions {

  textarea.e-input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group textarea,
  .e-input-group.e-control-wrapper textarea {
    height: 300px !important;
    resize: none !important;
  }
}

.e-btn.e-flat:disabled {
  /* color: rgba(0, 0, 0, 0.26) !important;
  background: transparent !important; */
  border-color: transparent !important;
  box-shadow: none !important;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.truncate-button-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 100%;
  display: block;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details {
  flex-direction: column;
}

// CUSTOM INPUT STYLES START

.e-input-group.e-control-wrapper.e-outline {
  margin-bottom: 0 !important;
}

.e-input-group.e-control-wrapper.e-outline.e-valid-input {
  margin-bottom: 0 !important;
}

// CUSTOM INPUT STYLES END

// CUSTOM BUTTON STYLES START

.custom-btn {
  padding: 12px 32px;
  border-radius: spacings.$border-radius-m;
  box-shadow: none !important;
  border: none !important;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.custom-cancel-fill-btn {
  border-radius: 4px !important;
  border: 0px solid #979797;
  background: var(--Border-color, #e0e0e0) !important;
  padding: 7px 24px;
  color: #272e5a;
  box-shadow: none !important;
  border: none !important;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.e-btn-content {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0.2px;
}

.e-btn.e-primary {
  background: #fac022 !important;
}

.custom-dialog-submit-btn {
  padding: 8px 32px !important;
  border-radius: 4px !important;
  background: #fac022 !important;
  box-shadow: none !important;
  border: none !important;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;

  &:disabled {
    opacity: 0.5;
    background: #fac022 !important;
  }
}

.e-btn.e-primary:disabled,
.e-css.e-btn.e-primary:disabled {
  background: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.e-btn.e-primary.custom-small-btn:disabled {
  opacity: 0.5;
  background: var(--Secondary-color, #fac022) !important;
}

.custom-small-btn {
  padding: 8px 32px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  border: none !important;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.gray-btn {
  background: #d9d9d9 !important;
}

.transparent-btn {
  background: transparent !important;
}

.remove-btn {
  cursor: pointer;
  border: unset;
  padding: 10px;
  border-radius: 50%;
  background: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background: rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    cursor: auto !important;
    background: transparent !important;

    fa-icon {
      color: rgba(158, 158, 158, 0.3) !important;
    }
  }

  fa-icon {
    color: #9e9e9e;
  }
}

// CUSTOM BUTTON STYLES END

.e-primary.custom-btn {
  color: #272e5a !important;
}

goatsports-translation {
  td:last-child {
    padding: unset !important;
  }

  tr[aria-expanded='true'],
  tr[aria-expanded='false'] {
    background: #fcfcfc !important;
  }
}

.e-input-group {
  border-radius: 6px !important;
}

goatsports-client-habits {
  goatsports-scheduler {
    .e-appointment {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-end !important;
    }
  }
}

.custom-cancel-btn {
  color: colors.$primary !important;
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  text-align: center !important;
  font-family: Arial !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0.2px !important;
  text-transform: uppercase !important;

  &:disabled {
    opacity: 0.5 !important;
  }
}

.e-btn-content {
  color: #272e5a;
  text-align: center;
  font-family: Arial !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 21px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.e-accordion .e-acrdn-item.e-select.e-item-focus {
  border: unset !important;
}

goatsports-chat-feed {
  goatsports-chat-data-header {
    .chat-data-header {
      padding-right: 64px !important;
    }
  }

  .e-expand-state {
    .e-acrdn-header {
      padding-right: 0px !important;
      background-color: #272e5a !important;
      color: #fff;
      border-radius: 12px 12px 0px 0px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .e-acrdn-header-content {
        display: flex !important;

        h3 {
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.257px;
        }

        h6 {
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.32px;
        }
      }
    }
  }

  .e-acrdn-header {
    padding-right: 0px !important;
    background-color: #272e5a !important;
    color: #fff;
    border-radius: 12px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .e-acrdn-header-content {
      display: flex !important;

      h3 {
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.257px;
      }

      h6 {
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.32px;
      }
    }
  }
}

// 5191 bug fixed
.e-popup-open::before {
  content: unset !important;
}

.e-btn.e-primary:disabled {
  background: #fac022 !important;
  opacity: 0.5 !important;
}

.e-work-cells:not(.e-work-days) {
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-style: solid;
  border-width: 0 0 1px 1px !important;
}

.e-tbar-btn-text {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: Arial;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.e-icon-down-arrow {
  font-size: 18px !important;
}

.e-icon-down-arrow::before {
  content: '\e729' !important;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-prev .e-icon-prev,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-next .e-icon-next {
  font-size: 10px !important;
}

.custom-draft-progress-btn {
  .e-btn.e-primary {
    opacity: 1;
    font-family: unset !important;
    border-radius: 4px;
    background: #d9d9d9 !important;
  }

  .e-btn.e-primary:disabled {
    opacity: 0.5;
    font-family: unset !important;
    border-radius: 4px;
    background: #d9d9d9 !important;
  }
}

goatsports-scheduler {
  .e-schedule {
    min-height: 320px !important;
  }
}

goatsports-reflections-scheduler,
goatsports-client-reflections {
  #content {
    color: #1178b2 !important;
  }

  .e-left-handler {
    background: #1178b2 !important;
    width: 8px !important;
    border-radius: 4px 0px 0px 4px;
    margin-right: 24px;
    height: 35px !important;
  }

  .flex span {
    color: #1178b2;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  .e-left-icon,
  .e-right-icon {
    color: #1178b2 !important;
  }

  goatsports-scheduler {
    .wrapper-data-template {
      border-left: solid #1178b2 8px !important;
    }
  }
}

goatsports-habits-scheduler,
goatsports-client-habits {
  #content {
    color: #11b29e !important;
  }

  .e-left-handler {
    background: #11b29e !important;
    width: 8px !important;
    border-radius: 4px 0px 0px 4px;
    margin-right: 24px;
    height: 35px !important;
  }

  .flex span {
    color: #11b29e;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  .e-left-icon,
  .e-right-icon {
    color: #11b29e !important;
  }

  goatsports-scheduler {
    .wrapper-data-template {
      border-left: solid #11b29e 8px !important;
    }
  }
}

.hide-btn {
  display: none !important;
}

.e-work-cells:not(.e-work-days),
.e-work-cells:not(.e-work-days):hover {
  background: #f9f3e4 !important;
}

goatsports-client-habits {
  .e-appointment-details {
    align-items: flex-end !important;
  }
}

.e-appointment-details:has(.e-left-icon) .wrapper-data-template {
  border-color: transparent !important;
}

.e-appointment-details:has(.e-right-icon) .wrapper-data-template {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.e-right-icon {
  background-color: #f6f6f6 !important;
  height: 44px;
  display: flex;
  align-items: center;
}

.display-none {
  display: none !important;
}

.e-dlg-closeicon-btn {
  padding-top: 4px !important;
}

.e-popup::before {
  border-radius: 6px !important;
}

.e-popup {
  border-radius: 6px !important;
  box-shadow: 0px 4px 6px -2px #10182808;

  box-shadow: 0px 12px 16px -4px #10182814 !important;
}

.e-filter-parent {
  padding: 8px !important;
}

.e-ddl.e-popup.e-alldrop.e-lib.e-control.e-popup-open {
  .e-content.e-dropdownbase {
    border-radius: 6px !important;
    border: 1px solid var(--Border-color, #E0E0E0);
    background: var(--White, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }
}