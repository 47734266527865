// pager custom styles start

.e-gridpager {
    align-items: center !important;
    line-height: unset !important;
  }
  
  .e-pager .e-pagerdropdown {
    display: inline-block;
    margin-left: 12px;
    margin-right: 0;
    position: relative;
    right: -96px;
    bottom: 19px;
    height: 39px;
    margin-top: -22px;
    vertical-align: middle;
    width: 90px;
  
    .e-input-group:not(.e-float-icon-left),
    .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
      border-width: unset;
      border: 1px solid rgba(0, 0, 0, 0.24) !important;
      border-radius: 4px;
      padding: 0 8px 0 12px;
    }
  }
  
  .e-pager div.e-parentmsgbar {
    float: right;
    padding-bottom: 9px;
    padding-right: 18px;
    padding-top: 22px;
  }
  
  .e-pager .e-pagercontainer {
    border-radius: 4px;
    border-style: solid;
    border-width: 0;
    display: inline-block;
    margin: 3px 6px 5px 18px;
    overflow: hidden;
    position: relative;
    top: 12px;
  }

.e-pagerconstant {
    margin: 0 0 0 12px !important;
  }

  .e-pagerdropdown {
    margin-left: 64px !important;
  }
  
  // pager custom styles end