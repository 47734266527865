.e-breadcrumb {
    height: 42px;
    display: flex !important;
    align-items: center;

    .e-breadcrumb-menu {
        display: none !important;
    }

    ol {
        .e-breadcrumb-item {
            font-family: Arial;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.32px;

            &.e-breadcrumb-separator {
                padding: 0 8px !important;
                color: #7c7c7c !important;
            }

            &[data-active-item] .e-breadcrumb-text {
                color: #272e5a !important;
            }
        }

        .e-breadcrumb-text {
            padding-left: 0 !important;
            font-size: 16px !important;

            &.e-breadcrumb-url {
                padding: 0 !important;
                color: #7c7c7c;
            }
        }
    }
}