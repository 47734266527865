.e-numeric .e-input-group-icon {

    &.e-spin-down:before,
    &.e-spin-up:before {
        color: #9e9e9e;
        font-size: 16px;
    }

    &.e-spin-down:before {
        content: "\e729" !important;
    }

    &.e-spin-up:before {
        content: "\e776" !important;
    }
}